import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  iconFillColor?: string;
  fontFillColor?: string;
};

export function IconEciu({
  iconFillColor,
  fontFillColor,
  ...restProps
}: Props) {
  const iconFill = iconFillColor ?? "#FFB71B";
  const fontFill = fontFillColor ?? "#051C2C";
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height={"1em"}
      width={"1em"}
      viewBox="0 0 242 175.3"
      {...restProps}
    >
      <g>
        <path
          fill={iconFill}
          d="M111.1,55.7c1,0.6,1.9,1.1,2.7,1.8L205.8,5v68.5L36.2,170.3v-68.5l63-36c-1.2-0.5-2.5-0.9-3.9-1.1l-62,35.4
		v75.2L208.7,75.2V0L111.1,55.7z"
        />
        <path
          fill={fontFill}
          d="M124.6,94.5c-1.8,15.5-14.6,25.7-34.2,25.7c-19.9,0-33.2-11.8-35.7-29l16-9.1c-0.1,1.1-0.2,2.2-0.2,3.3
		c0,12.9,7.3,21.1,19.9,21.1c9.6,0,15.9-4.7,17.6-12.1H124.6z M113.9,57.5c-0.9-0.6-1.8-1.2-2.7-1.8c-5.5-3.2-12.5-5-20.7-5
		c-22.1,0-36.1,14.6-36.1,34.7c0,0.9,0,1.8,0.1,2.6l17-9.7c2.2-8.8,8.9-14.1,19.1-14.1c1.7,0,3.3,0.2,4.8,0.4
		c1.4,0.3,2.7,0.6,3.9,1.1c4.7,2,7.9,5.7,9,10.5h16.5C123.7,68.5,119.9,62,113.9,57.5z M44.7,64.8V52.6H0v65.7h33.3v-12.2H15.5V91.6
		h16.7V79.7H15.5V64.8H44.7z M36.2,106.1v12.2h8.5v-12.2H36.2z M141.5,52.6v57.6l15.5-8.9V52.6H141.5z M141.5,113.5v4.8h15.5v-13.6
		L141.5,113.5z M179.6,52.6v35.9l15.5-8.9v-27H179.6z M226.4,52.6v38c0,6.6-3,13.9-15.6,13.9s-15.6-7.3-15.6-13.9v-7.7l-15.5,8.9
		v0.1c0,5,1.4,10.2,3.8,14h0c4.9,7.6,14.4,12.4,27.3,12.5h0.1c13,0,22.4-4.8,27.3-12.5h0c2.4-3.8,3.7-9,3.8-14V52.6H226.4z"
        />
      </g>
    </svg>
  );
}
