import { ServerRequest } from "../../types";
import {
  createBackendApiClientWithReq,
  isBackendClientAllowed,
} from "./createBackendApiClient";
import {
  LearningOpportunityBase,
  LearningOpportunityStatus,
  LearningOpportunityType,
} from "./generated";
import {
  LearningOpportunityListItem,
  mapLearningOpportunityList,
} from "./learningOpportunity";

type Func = () => Promise<LearningOpportunityBase[]>;

export const getAllLearningOpportunitiesOnTopic = async (
  topicId: string,
  excludeId?: string,
  learningOpportunityType?: LearningOpportunityType,
  sortByPublishedDate?: boolean,
  req?: ServerRequest
) => {
  const client = createBackendApiClientWithReq(req);
  const list = getLearningOpportunityList(
    () =>
      client.learningOpportunity_GetAllOnTopic(
        topicId,
        learningOpportunityType,
        sortByPublishedDate
      ),
    excludeId
  );
  return list;
};

export const getStartpagePromotedLearningOpportunityList = async (
  req?: ServerRequest
) => {
  const list = getLearningOpportunityList(() => {
    const client = createBackendApiClientWithReq(req);
    return client.learningOpportunity_GetStartpagePromoted();
  });
  return list;
};

export async function getRecommendationsMostPopularByTopic(
  topicId: string,
  req?: ServerRequest
) {
  const list = await getLearningOpportunityList(() => {
    const client = createBackendApiClientWithReq(req);
    return client.learningOpportunity_GetRecommendationsByVisitsForTopics(
      topicId
    );
  });
  return list;
}

export async function getRecommendationsGetStartedOnTopic(
  topicId: string,
  req?: ServerRequest
) {
  return await getLearningOpportunityList(() => {
    const client = createBackendApiClientWithReq(req);
    return client.learningOpportunity_GetRecommendationsGetStartedOnTopic();
  });
}

export const StatusToIncludeInRecommendationLists = [
  LearningOpportunityStatus.ComingSoon,
  LearningOpportunityStatus.OpenForApplication,
];

const getLearningOpportunityList = async (
  fetchFunc: Func,
  excludeId?: string
): Promise<LearningOpportunityListItem[]> => {
  if (!isBackendClientAllowed) {
    // Default to empty list if backend client isn't allowd (= build phase etc)
    return [];
  }

  try {
    const list = await fetchFunc();
    const mappedList = mapLearningOpportunityList(
      list,
      StatusToIncludeInRecommendationLists
    );
    return excludeId
      ? mappedList.filter((x) => x.id !== excludeId)
      : mappedList;
  } catch {
    // Default to empty list on error instead of breaking entire page for related lists.
    return [];
  }
};
