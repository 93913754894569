import { createBackendApiClient, createBackendApiClientWithReq } from "./createBackendApiClient";
import { ServerRequest } from "../../types";
import {
  Competence,
  CompetenceBase,
  CompetenceBaseTree,
  CompetenceBaseWithChildren,
} from "./generated";
import { buildCompetenceUrl } from "@/utils/url";
import { createImageUrl } from "@/server/backend-api/mapHelpers";
import {
  getTopicsWithAllSubtopics,
  sortTopicsBySpecialTopicOrder,
} from "@/utils/competenceTree";

export const rootCompetenceNames = {
  sdg: "SDG/Domain",
  preparatory: "Preparatory",
  transversal: "Transversal",
} as const;

export interface CompetenceBaseEx extends Omit<CompetenceBase, "imagesPath"> {
  imageUri: string | null;
  url: string;
}

export interface CompetenceBaseWithChildrenEx
  extends CompetenceBaseWithChildren {
  imageUri: string | null;
  children: CompetenceBaseWithChildrenEx[];
  url: string;
}

export interface CompetenceEx
  extends Omit<Competence, "imagesPath" | "subTopics"> {
  imageUri: string | null;
  url: string;
  subTopics: CompetenceBaseEx[] | null;
}

export interface CompetenceBaseTreeEx {
  sdg: CompetenceBaseWithChildrenEx;
  preparatory: CompetenceBaseWithChildrenEx;
  transversal: CompetenceBaseWithChildrenEx;
}

export const getCompetences = async (): Promise<CompetenceBaseEx[]> => {
  const client = createBackendApiClient();
  const competences = await client.competence_GetAll();
  return competences.map(mapCompetenceBase);
};

export function mapCompetenceBase(data: CompetenceBase): CompetenceBaseEx {
  return {
    ...data,
    imageUri: createImageUrl(data.imagesPath, 280, 280),
    url: buildCompetenceUrl(data.id, data.name),
  };
}

export const getTopic = async (id: string, req?: ServerRequest): Promise<CompetenceEx> => {
  
  if(req !== undefined && req !== null) {
    const client = createBackendApiClientWithReq(req);
    const data = await client.competence_GetTopicById(id);
  return mapCompetence(data);
  }
  else {
    const client = createBackendApiClient();
    const data = await client.competence_GetTopicById(id);
  return mapCompetence(data);
  }
  
};

const mapCompetence = (data: Competence): CompetenceEx => {
  return {
    ...data,
    url: buildCompetenceUrl(data.id, data.name),
    imageUri: createImageUrl(data.imagesPath, 280, 280),
    subTopics: data.subTopics?.map(mapCompetenceBase) ?? null,
  };
};

export function mapCompetenceGetTree(tree: CompetenceBaseTree) {
  return {
    sdg: mapCompetenceBaseWithChildrenByName(tree, "sdg"),
    preparatory: mapCompetenceBaseWithChildrenByName(tree, "preparatory"),
    transversal: mapCompetenceBaseWithChildrenByName(tree, "transversal"),
  };
}

export async function getCompetenceTree(): Promise<CompetenceBaseTreeEx> {
  const client = createBackendApiClient();
  const tree = await client.competence_GetTree();
  return mapCompetenceGetTree(tree);
}

export function mapCompetenceBaseWithChildrenByName(
  tree: CompetenceBaseTree,
  rootCompetenceName: keyof typeof rootCompetenceNames
): CompetenceBaseWithChildrenEx {
  const rootCompetence = tree.rootCompetences.find(
    (c) => c.name === rootCompetenceNames[rootCompetenceName]
  );
  if (!rootCompetence) {
    throw new Error(`Could not find root competence ${rootCompetenceName}`);
  }
  return mapCompetenceBaseWithChildren(rootCompetence);
}

export function mapCompetenceBaseWithChildren(
  data: CompetenceBaseWithChildren
): CompetenceBaseWithChildrenEx {
  return {
    ...data,
    imageUri: createImageUrl(data.imagesPath, 280, 280),
    children: data.children.map(mapCompetenceBaseWithChildren),
    url: buildCompetenceUrl(data.id, data.name),
  };
}

export async function getSecondLevelCompetences() {
  const tree = await getCompetenceTree();
  const competences = getTopicsWithAllSubtopics(tree);
  return sortTopicsBySpecialTopicOrder(competences);
}
