import { getAuthStatus } from "@/server/getAuthStatus";
import { Assert } from "@/utils/assert";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import config from "../../config";
import { ServerRequest } from "../../types";
import { BackendApiClient, FileParameter } from "./generated";
import FormData from "form-data";

/**
 * Extension client that overrides some methods from the generated client.
 * This is needed because multi-part form data uploads are broken in the generated client.
 * NOTE: This needs to be done for every method that uses file uploads.
 */
export class ExtBackendApiClient extends BackendApiClient {
  private readonly extBaseUrl: string;
  private readonly extHttp: BackendApiClient["http"];

  constructor(baseUrl: string, http: BackendApiClient["http"]) {
    super(baseUrl, http);
    this.extBaseUrl = baseUrl;
    this.extHttp = http;
  }

  /**
   * Most code is copied from corresponding method in the generated client.
   */
  async profile_UploadFile(
    file: FileParameter,
    documentTypeName: string,
    signal?: AbortSignal | undefined
  ): Promise<string> {
    let url_ = this.extBaseUrl + "/api/v1/profile/file?";
    if (documentTypeName !== undefined && documentTypeName !== null)
      url_ +=
        "documentTypeName=" + encodeURIComponent("" + documentTypeName) + "&";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = new FormData();
    if (file !== null && file !== undefined)
      content_.append(
        "file",
        file.data,
        file.fileName ? file.fileName : "file"
      );

    const headers = content_.getHeaders();
    let options_: RequestInit = {
      body: content_.getBuffer(),
      method: "PUT",
      signal,
      headers: {
        Accept: "application/json",
        ...headers,
      },
    };
    return this.extHttp.fetch(url_, options_).then((_response: Response) => {
      return this.processProfile_UploadFile(_response);
    });
  }
}

export function createBackendApiClientWithReq(req?: ServerRequest) {
  const auth = req ? getAuthStatus(req) : undefined;
  return createBackendApiClient(auth?.token);
}

export const createBackendApiClient = (token?: string) => {
  assertBackendClientAllowed();

  /*
   * Reminder:
   * We would need to use the http argument in BackendApiClient constructor even
   * if we were to remove headers manipulation as NextJS polyfills `fetch` in
   * global scope for node env, but generated code assumes `window.fetch`
   */
  const http = {
    fetch: async (input: RequestInfo, init: RequestInit): Promise<Response> => {
      if (token) {
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return fetch(input, init);
    },
  };

  const baseUrl = config.api.baseUrl;
  Assert.notNull(baseUrl, "createBackendApiClient -> config.api.baseUrl");
  return new ExtBackendApiClient(baseUrl, http);
};

/* API calls should never be made:
 *  - From client side (browser)
 *  - During build phase (getStaticProps)
 */
export const isBackendClientAllowed =
  typeof window === "undefined" && // isServer
  process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD; // not build phase

export const assertBackendClientAllowed = () => {
  Assert.check(typeof window === "undefined", 'typeof window === "undefined"');
  Assert.check(
    process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD,
    "process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD"
  );
};
