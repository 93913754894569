import { SVGProps } from "react";

const SvgIconActionUserGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 12c.407-1.791 2.043-3.13 4-3.13 1.957 0 3.593 1.339 4 3.13M8 4c1.377 0 2.494 1.09 2.494 2.435 0 1.344-1.117 2.434-2.494 2.434-1.377 0-2.494-1.09-2.494-2.434C5.506 5.09 6.623 4 8 4zm3.5 5.517c.422-.298.94-.474 1.5-.474s1.079.176 1.5.474M13 6c.86 0 1.559.681 1.559 1.522 0 .84-.698 1.521-1.559 1.521-.86 0-1.559-.68-1.559-1.521C11.441 6.682 12.14 6 13 6zM1.5 9.517c.422-.298.94-.474 1.5-.474s1.079.176 1.5.474M3 6c.86 0 1.559.681 1.559 1.522 0 .84-.698 1.521-1.559 1.521-.86 0-1.559-.68-1.559-1.521C1.441 6.682 2.14 6 3 6z"
      stroke="currentColor"
      strokeWidth={0.75}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconActionUserGroup;
