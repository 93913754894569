import { SVGProps } from "react";

const SvgIconActionPaperClip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.071 17.414 14.586 25.9a5 5 0 0 1-7.071 0L6.1 24.485a5 5 0 0 1 0-7.07l8.485-8.486h0m-6.364 6.364L18.828 4.686a3 3 0 0 1 4.243 0l1.414 1.415a3 3 0 0 1 0 4.242L13.88 20.95h0"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconActionPaperClip;
