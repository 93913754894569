import {
  ReactPlugin,
  useAppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  IApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { NextWebVitalsMetric } from "next/app";
import { getCookieConsent } from "@/utils/cookies/cookies";

export const initClientSideAppInsights = (clientConnectionString?: string) => {
  const reactPlugin = new ReactPlugin();
  let appInsights: ApplicationInsights | undefined;

  const isServer = typeof window === "undefined";
  if (!isServer && clientConnectionString) {
    const consentedCookies = getCookieConsent();
    appInsights = new ApplicationInsights({
      config: {
        connectionString: clientConnectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        disableCookiesUsage: !consentedCookies.haveConsentForWebAnalytics,
      },
    });
    appInsights.loadAppInsights();
  }

  return { appInsights, reactPlugin };
};

export const trackWebVitals = (
  appInsights: IApplicationInsights | undefined,
  metric: NextWebVitalsMetric
) => {
  if (
    appInsights &&
    process.env.NEXT_PUBLIC_DXP_APP_INSIGHTS_ENABLE_WEB_VITALS_METRIC === "true"
  ) {
    appInsights.trackMetric(
      {
        name: metric.name,
        average: metric.value,
        sampleCount: 1,
      },
      { ...metric }
    );
  }
};

type CustomProperties = Record<string, unknown>;
type Exception = Error | unknown;

const logException = (
  appInsights: ReactPlugin,
  error: Exception,
  customProperties?: CustomProperties
) => {
  const exception =
    error instanceof Error
      ? error
      : new Error(`Unknown error: ${customProperties?.message}`);

  if (process.env.NODE_ENV === "development") {
    console.error(JSON.stringify({ exception, customProperties }));
  } else {
    appInsights.trackException({ exception }, customProperties);
  }
};

const logTrace = (
  appInsights: IApplicationInsights | ReactPlugin,
  severityLevel: SeverityLevel,
  message: string,
  customProperties?: CustomProperties
) => {
  if (process.env.NODE_ENV === "development") {
    console.log(severityLevel, message, customProperties);
  } else {
    appInsights.trackTrace(
      {
        message: message,
        severityLevel: severityLevel,
      },
      customProperties
    );
  }
};

const logEvent = (
  appInsights: IApplicationInsights | ReactPlugin,
  name: string,
  customProperties?: CustomProperties
) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`TrackEvent: ${name} properties=${customProperties}`);
  } else {
    appInsights.trackEvent({
      name: name,
      properties: customProperties,
    });
  }
};

export const useLogging = () => {
  const appInsightsReactPlugin = useAppInsightsContext();
  return {
    logException: (error: Exception, customProperties?: CustomProperties) =>
      logException(appInsightsReactPlugin, error, customProperties),

    logTrace: (
      severityLevel: SeverityLevel,
      message: string,
      customProperties?: CustomProperties
    ) =>
      logTrace(
        appInsightsReactPlugin,
        severityLevel,
        message,
        customProperties
      ),

    logErrorTrace: (message: string, customProperties?: CustomProperties) =>
      logTrace(
        appInsightsReactPlugin,
        SeverityLevel.Error,
        message,
        customProperties
      ),

    logInfoTrace: (message: string, customProperties?: CustomProperties) =>
      logTrace(
        appInsightsReactPlugin,
        SeverityLevel.Information,
        message,
        customProperties
      ),
    trackEvent: (name: string, properties?: CustomProperties) => {
      logEvent(appInsightsReactPlugin, name, properties);
    },
  };
};
