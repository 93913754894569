import config from "../config";
import Script from "next/script";
import React from "react";

export function RecaptchaScript() {
  const recaptchaSrc = `https://www.google.com/recaptcha/api.js?render=${
    config.recaptcha.siteKey ?? ""
  }`;
  return <Script src={recaptchaSrc}></Script>;
}
