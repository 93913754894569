import { mcn } from "@/utils/mergeClassNames";
import Link from "next/link";
import { headerHeightClassnames } from "./Header";
import Image from "next/image";
import logoWhiteOrange from "../../public/images/logo-white-orange.svg";

export const HeaderLogoLink = (props: { href?: string }) => (
  <div
    className={mcn(
      "flex laptop:justify-center items-center flex-shrink-0",
      headerHeightClassnames
    )}
  >
    <Link
      href={props.href ?? "/"}
      className={mcn(
        "focus-visible:default-focus",
        "px-xs mx-xs max-w-[100px] h-[40px] laptop:h-[48px]"
      )}
    >
      <Image
        aria-label="Go to home page"
        src={logoWhiteOrange}
        className="h-full"
        alt="ECIU logo"
        priority={true} // Pretty much always visible on page load, so prioritize it.
      />
    </Link>
  </div>
);
