import { SVGProps } from "react";

const SvgIconActionBookmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 1.5h9v13L8 10l-4.5 4.5v-13z"
      stroke="currentColor"
      strokeWidth={0.75}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconActionBookmark;
