import type { NextApiRequest, NextApiResponse } from "next";
import { getAuthStatus } from "../getAuthStatus";
import { createBackendApiClient } from "./createBackendApiClient";
import { BackendApiClient, FileResponse } from "./generated";

export const backendFileHandler = async (
  req: NextApiRequest,
  res: NextApiResponse,
  fetchFn: (client: BackendApiClient) => Promise<FileResponse | null>
) => {
  const auth = getAuthStatus(req);
  const client = createBackendApiClient(
    auth.isAuthenticated ? auth.token : undefined
  );
  const fileResponse = await fetchFn(client);
  if (!fileResponse) {
    res.status(404).send("");
    return;
  }

  const contentType =
    fileResponse.headers && fileResponse.headers["content-type"];
  if (contentType) {
    res.setHeader("Content-Type", contentType);
  }

  const arrayBuffer = await fileResponse.data.arrayBuffer();
  const buffer = Buffer.from(arrayBuffer);
  res.status(200);
  res.send(buffer);
};
