import {
  createBackendApiClient,
  createBackendApiClientWithReq,
} from "./createBackendApiClient";
import {
  Organisation as ApiOrganisation,
  OrganisationBase as ApiOrganisationBase,
} from "./generated";
import {
  LearningOpportunityListItem,
  mapLearningOpportunityListItem,
} from "./learningOpportunity";
import { createImageUrl } from "./mapHelpers";
import { ServerRequest } from "../../types";
import { buildOrganisationUrl } from "@/utils/url";
import { Assert } from "@/utils/assert";

export type OrganisationBase = {
  id: string;
  name: string;
  description: string | null;
  location: string | null;
  logoImageUri: string | null;
  logoImageSmallUri: string | null;
  backgroundImageUri: string | null;
  eciuMemberUniversity: boolean;
  homeUrl: string | null;
  url: string;
  eciuMemberUniversityDomainName: string | null;
};

export type Organisation = OrganisationBase & {
  learningOpportunitiesAsHost: LearningOpportunityListItem[];
  learningOpportunitiesAsPartner: LearningOpportunityListItem[];
};

export const getOrganisationsBase = async (): Promise<OrganisationBase[]> => {
  const client = createBackendApiClient();
  const res = await client.organisation_GetAll();
  return res.map(mapOrganisationBase);
};

export const getMemberUniversities = async () => {
  const orgs = await getOrganisationsBase();
  return orgs.filter((org) => org.eciuMemberUniversity);
};

export async function getOrganisation(
  organisationId: string,
  req?: ServerRequest
) {
  const client = createBackendApiClientWithReq(req);
  const res = await client.organisation_GetById(organisationId);
  return mapOrganisation(res);
}

function mapOrganisation(org: ApiOrganisation): Organisation {
  const base = mapOrganisationBase(org);

  return {
    ...base,
    learningOpportunitiesAsHost: org.learningOpportunitiesAsHost.map(
      mapLearningOpportunityListItem
    ),
    learningOpportunitiesAsPartner: org.learningOpportunitiesAsPartner.map(
      mapLearningOpportunityListItem
    ),
  };
}

export function mapOrganisationBase(
  org: ApiOrganisationBase
): OrganisationBase {
  const name = org.name;
  Assert.notNull(name);
  return {
    ...org,
    name,
    logoImageUri: createImageUrl(org.logoTypeImagesPath, 280, 280),
    logoImageSmallUri: createImageUrl(org.logoTypeImagesPath, 40, 40),
    backgroundImageUri: createImageUrl(org.backgroundImagesPath, 1280, 720),
    homeUrl: org.url ?? null,
    url: buildOrganisationUrl(org.id, org.name),
  };
}
