import { Assert } from "@/utils/assert";
import { ServerRequest } from "../../types";
import { getAuthStatus } from "../getAuthStatus";
import { createBackendApiClientWithReq } from "./createBackendApiClient";
import {
  LearningOpportunityBaseWithMicroCredentials,
  mapLearningOpportunityListItem,
} from "./learningOpportunity";
import { LearningOpportunityBaseWithMicroCredentials as ApiLearningOppBaseWithMicroCred } from "./generated";
import { mapUserAdmissionWithMicroCredentials } from "@/server/backend-api/learningOpportunityUserAdmission";

export const getMyLearningOpportunityList = async (
  userRequest: ServerRequest
): Promise<LearningOpportunityBaseWithMicroCredentials[]> => {
  const auth = getAuthStatus(userRequest);
  if (!auth.isAuthenticated) {
    Assert.notImplemented();
  }

  const client = createBackendApiClientWithReq(userRequest);
  const list = await client.learningOpportunity_GetMy();
  return list.map(mapLearningOpportunityBaseWithMicroCred);
};

export function mapLearningOpportunityBaseWithMicroCred(
  learningOpp: ApiLearningOppBaseWithMicroCred
): LearningOpportunityBaseWithMicroCredentials {
  const mapped = mapLearningOpportunityListItem(learningOpp);
  const withCred: LearningOpportunityBaseWithMicroCredentials = {
    ...mapped,
    userAdmissionData: mapUserAdmissionWithMicroCredentials(
      learningOpp.userData
    ),
    generateMicroCredentials: learningOpp.generateMicroCredentials,
    generateDigitalCredentials: learningOpp.generateDigitalCredentials,
  };
  return withCred;
}
