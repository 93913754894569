import { SVGProps } from "react";

const SvgIconActionSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.464 19.536A9 9 0 1 0 25.192 6.808a9 9 0 0 0-12.728 12.728Zm0 0-5.656 5.656"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconActionSearch;
