import { mcn } from "@/utils/mergeClassNames";
import { PropsWithChildren } from "react";
import { HeadlessUiMenuLink } from "./HeadlessUiLinkWrapper";

type LinkItemProps = PropsWithChildren<{
  href: string;
  className?: string;
}>;

export const BrowseMenuLinkItem = (props: LinkItemProps) => {
  const { className, ...rest } = props;
  return (
    <HeadlessUiMenuLink
      className={mcn(
        "h-12 flex items-center pl-lg rounded-2",
        "text-white text-style-subtitle2",
        "hover:bg-light-yellow/5",
        className
      )}
      {...rest}
    />
  );
};
