import { IconActionChevronDown } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import { Menu } from "@headlessui/react";
import { PropsWithChildren } from "react";

export const BrowseMenuDesktop = (props: PropsWithChildren<unknown>) => {
  return (
    <div className="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              aria-label="Browse learning opportunities and topics"
              className={mcn(
                "group",
                "bg-blue text-white px-4 py-1 rounded-full",
                "text-style-subtitle2",
                "flex items-center gap-xs",
                "focus-visible:default-focus"
              )}
            >
              <span className="group-hover:text-yellow-100">Browse</span>
              <IconActionChevronDown
                className={mcn(open && "text-yellow-100")}
              />
            </Menu.Button>
            <Menu.Items
              className={mcn(
                "bg-light-blue shadow-elevation-3 w-[832px]",
                "p-xl rounded-2",
                "absolute mt-xs",
                "outline-none"
              )}
            >
              {props.children}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};
