import { GetServerSidePropsContext, Redirect } from "next";
import { ChallengePageQueryParams } from "../pages/challenges/[id]/[title]";
import { Assert } from "./assert";
import { MicroModulePageQueryParams } from "../pages/micro-modules/[id]/[title]";

type QueryObject = {
  [key: string]: string | string[] | undefined;
};

function asArray(value: string | string[] | undefined): string[] | undefined {
  if (value === undefined) {
    return undefined;
  }
  return typeof value === "string" ? [value] : value;
}

type PartialUrl = Pick<URL, "pathname" | "search" | "searchParams">;

export function getPartialResolvedURLFromContext(
  context: GetServerSidePropsContext
): PartialUrl {
  // URL constructor requires a base.
  // Since we do not expose any URL fields related to host, port, etc, localhost works fine.
  return new URL(context.resolvedUrl, "https://localhost");
}

export function buildUrlWithQuery<T extends QueryObject>(
  root: string,
  query: T
) {
  let url = `${root}?`;
  const keys = Object.keys(query);
  for (const key of keys) {
    const values = asArray(query[key]);
    if (values !== undefined) {
      for (const value of values) {
        url = `${url}${key}=${encodeURIComponent(value)}&`;
      }
    }
  }
  // Strip trailing ? and &.
  url = url.replace(/[?&]$/, "");
  return url;
}

export function buildUrlWithUrlSearchParams(
  root: string,
  searchParams: URLSearchParams
) {
  const convertedQuery: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    convertedQuery[key] = value;
  }
  return buildUrlWithQuery(root, convertedQuery);
}

export function buildChallengeUrl(
  challengeId: string,
  challengeTitle: string | null,
  query?: ChallengePageQueryParams
) {
  const base = `/challenges/${challengeId}/${encodeAsReadableUrlSuffix(
    challengeTitle ?? challengeId
  )}`;
  return buildUrlWithQuery(base, query ?? {});
}

export function buildMicroModuleUrl(
  moduleId: string,
  moduleTitle: string | null,
  query?: MicroModulePageQueryParams
) {
  const base = `/micro-modules/${moduleId}/${encodeAsReadableUrlSuffix(
    moduleTitle ?? moduleId
  )}`;
  return buildUrlWithQuery(base, query ?? {});
}

export function buildCompetenceUrl(
  competenceId: string,
  competenceName: string | null
) {
  return `/topics/${competenceId}/${encodeAsReadableUrlSuffix(
    competenceName ?? competenceId
  )}`;
}

export function buildOrganisationUrl(
  organisationId: string,
  organisationName: string | null
) {
  return `/organisations/${organisationId}/${encodeAsReadableUrlSuffix(
    organisationName ?? organisationId
  )}`;
}

export function buildCertificateDownloadUrl(learningOppId: string) {
  return `/api/profile/certificate/${learningOppId}`;
}

/**
 * Encodes given object name into something suitable as a human-readable URL suffix.
 * Example: "The Challenge Of The Century (2nd-edition)" => "the-challenge-of-the-century-2nd-edition"
 * @param name
 */
export function encodeAsReadableUrlSuffix(name: string): string {
  const titleExpression = /[A-Za-z0-9 -]+/gm;
  const matches = name.match(titleExpression);
  Assert.check(matches !== null);
  const cleanTitle = matches.reduce((prev, match) => prev + match, "");
  return cleanTitle
    .trim()
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/-{2,}/gm, "-");
}

type GetServerSidePropsRedirectResult = {
  redirect: Redirect;
};

function makeServerSidePropsRedirect(
  newUrl: string
): GetServerSidePropsRedirectResult {
  return {
    redirect: {
      permanent: true,
      destination: newUrl,
    },
  };
}

/**
 * This interface matches all objects that currently use readable URL suffixes like Challenge, Organisation etc.
 */
type UrlObj = {
  url: string;
};

export function getRedirectIfUrlMismatch(
  context: GetServerSidePropsContext,
  urlObj: UrlObj
): GetServerSidePropsRedirectResult | undefined {
  // URL constructor needs a base, but for our purposes it won't matter.
  // localhost therefore works fine.
  const resolvedUrl = getPartialResolvedURLFromContext(context);
  if (resolvedUrl.pathname !== urlObj.url) {
    const urlWithParams = urlObj.url + resolvedUrl.search;
    return makeServerSidePropsRedirect(urlWithParams);
  }
}
