import {
  CompetenceBaseEx,
  CompetenceBaseTreeEx,
  CompetenceBaseWithChildrenEx,
  CompetenceEx,
} from "@/server/backend-api";
import { Assert } from "@/utils/assert";

/**
 * Sometimes we need to have a dummy tree to avoid null checks down the line.
 * Mostly when backend client is not available.
 */
export const DUMMY_TREE: CompetenceBaseTreeEx = {
  sdg: {
    id: "sdg",
    name: null,
    children: [],
    url: "",
    description: null,
    subTitle: null,
    imagesPath: null,
    imageUri: null,
    shortDescription: null,
    skills: [],
    hideFromMotivationScan: false,
    showInBrowseBanner: false,
    showAsFilterTopic: false,
    sortOrderInMotivationScan: 0,
    sortOrderInBrowseBanner: 0,
    sortOrderInFilterTopic: 0,
  },
  preparatory: {
    id: "preparatory",
    name: null,
    children: [],
    url: "",
    description: null,
    subTitle: null,
    imagesPath: null,
    imageUri: null,
    shortDescription: null,
    skills: [],
    hideFromMotivationScan: true,
    showInBrowseBanner: false,
    showAsFilterTopic: false,
    sortOrderInMotivationScan: 0,
    sortOrderInBrowseBanner: 0,
    sortOrderInFilterTopic: 0,
  },
  transversal: {
    id: "transversal",
    name: null,
    children: [],
    url: "",
    description: null,
    subTitle: null,
    imagesPath: null,
    imageUri: null,
    shortDescription: null,
    skills: [],
    hideFromMotivationScan: false,
    showInBrowseBanner: false,
    showAsFilterTopic: false,
    sortOrderInMotivationScan: 0,
    sortOrderInBrowseBanner: 0,
    sortOrderInFilterTopic: 0,
  },
};

/**
 * Second level competences (below transversal, sdg etc).
 */
export const SecondLevelCompetenceNameMap = {
  energyAndSustainability: "Energy and sustainability",
  circularEconomy: "Circular economy",
  transportAndMobility: "Transport and Mobility",
  resilientCommunities: "Resilient communities",
  entrepreneurship: "Entrepreneurship, technology and innovation",
  criticalThinking: "Critical and innovative thinking",
  interPersonal: "Inter-personal skills",
  intraPersonal: "Intra-personal skills",
  globalCitizenship: "Global citizenship",
  mediaAndInformation: "Media and information literacy",
  languageLearning: "Language learning",
} as const;

type SecondLevelCompetenceName =
  (typeof SecondLevelCompetenceNameMap)[keyof typeof SecondLevelCompetenceNameMap];

export function getSecondLevelCompetence<
  T extends CompetenceBaseEx | CompetenceEx
>(competenceName: SecondLevelCompetenceName, competences: T[]): T | null {
  for (const competence of competences) {
    if (competenceName === competence.name) {
      return competence;
    }
  }
  return null;
}

export function getCompetenceFromTreeById(
  competenceId: string,
  tree: CompetenceBaseTreeEx
) {
  const competences = getFlatCompetencesWithoutRoot(tree);
  for (const competence of competences) {
    if (competence.id === competenceId) {
      return competence;
    }
  }
  Assert.check(false, `Competence with id ${competenceId} not found.`);
}

export function getTopicsWithAllSubtopics(
  tree: CompetenceBaseTreeEx
): CompetenceBaseWithChildrenEx[] {
  return [
    ...tree.sdg.children,
    ...tree.preparatory.children,
    ...tree.transversal.children,
  ];
}

export function getFlatCompetencesWithoutRoot(
  tree: CompetenceBaseTreeEx
): CompetenceBaseWithChildrenEx[] {
  return [
    ...getFlatChildren(tree.sdg),
    ...getFlatChildren(tree.preparatory),
    ...getFlatChildren(tree.transversal),
  ];
}

function getFlatChildren(
  competence: CompetenceBaseWithChildrenEx
): CompetenceBaseWithChildrenEx[] {
  const children = [];
  for (const child of competence.children) {
    children.push(child);
    children.push(...getFlatChildren(child));
  }
  return children;
}

function competenceNameComparator(competenceName: string): number {
  switch (competenceName) {
    case SecondLevelCompetenceNameMap.energyAndSustainability:
      return 11;
    case SecondLevelCompetenceNameMap.circularEconomy:
      return 10;
    case SecondLevelCompetenceNameMap.transportAndMobility:
      return 9;
    case SecondLevelCompetenceNameMap.resilientCommunities:
      return 8;
    case SecondLevelCompetenceNameMap.entrepreneurship:
      return 7;
    case SecondLevelCompetenceNameMap.criticalThinking:
      return 6;
    case SecondLevelCompetenceNameMap.interPersonal:
      return 5;
    case SecondLevelCompetenceNameMap.intraPersonal:
      return 4;
    case SecondLevelCompetenceNameMap.globalCitizenship:
      return 3;
    case SecondLevelCompetenceNameMap.mediaAndInformation:
      return 2;
    case SecondLevelCompetenceNameMap.languageLearning:
      return 1;
    default:
      return 0;
  }
}

export function sortTopicsBySpecialTopicOrder<T extends CompetenceBaseEx>(
  competences: T[]
): T[] {
  const _copy = [...competences];
  return _copy.sort((left, right) => {
    const leftScore = left.name ? competenceNameComparator(left.name) : 0;
    const rightScore = right.name ? competenceNameComparator(right.name) : 0;
    return rightScore - leftScore;
  });
}

export function sortTopicsInBrowseBanner<T extends CompetenceBaseEx>(
  competences: T[]
): T[] {
  const _copy = [...competences].filter(
    (competence) => competence.showInBrowseBanner
  );
  return _copy.sort((left, right) => {
    const leftScore = left.sortOrderInBrowseBanner
      ? left.sortOrderInBrowseBanner
      : 0;
    const rightScore = right.sortOrderInBrowseBanner
      ? right.sortOrderInBrowseBanner
      : 0;
    return leftScore - rightScore;
  });
}

export function sortTopicsInFilterTopic<T extends CompetenceBaseEx>(
  competences: T[]
): T[] {
  const _copy = [...competences].filter(
    (competence) => competence.showAsFilterTopic
  );
  return _copy.sort((left, right) => {
    const leftScore = left.sortOrderInFilterTopic
      ? left.sortOrderInFilterTopic
      : 0;
    const rightScore = right.sortOrderInFilterTopic
      ? right.sortOrderInFilterTopic
      : 0;
    return leftScore - rightScore;
  });
}

export function sortTopicsInMotivationScan<T extends CompetenceBaseEx>(
  competences: T[]
): T[] {
  const _copy = [...competences].filter(
    (competence) => competence.hideFromMotivationScan != null && !competence.hideFromMotivationScan
  );
  return _copy.sort((left, right) => {
    const leftScore = left.sortOrderInMotivationScan
      ? left.sortOrderInMotivationScan
      : 0;
    const rightScore = right.sortOrderInMotivationScan
      ? right.sortOrderInMotivationScan
      : 0;
    return leftScore - rightScore;
  });
}

export class CompetenceRelationshipMap {
  private readonly _parentIdToChildIds: Map<string, string[]>;
  private readonly _childIdToParentId: Map<string, string>;

  constructor(tree: CompetenceBaseTreeEx) {
    this._parentIdToChildIds = new Map();
    this._childIdToParentId = new Map();
    const secondLevelCompetences = getTopicsWithAllSubtopics(tree);
    for (const competence of secondLevelCompetences) {
      this._addRecursively(competence);
    }
  }

  private _addRecursively(competence: CompetenceBaseWithChildrenEx) {
    const childrenIds = competence.children.map((child) => child.id);
    this._parentIdToChildIds.set(competence.id, childrenIds);
    for (const child of competence.children) {
      this._childIdToParentId.set(child.id, competence.id);
      this._addRecursively(child);
    }
  }

  /**
   * Returns the chain of parent ids for the given leaf competence id.
   * @param leafCompetenceId The leaf competence id.
   */
  getRelationshipChain(leafCompetenceId: string): string[] {
    const result = [];
    let currentId: string | null = leafCompetenceId;
    while (currentId !== null) {
      result.push(currentId);
      currentId = this._childIdToParentId.get(currentId) ?? null;
    }
    return result;
  }

  getParent(competenceId: string): string | undefined {
    return this._childIdToParentId.get(competenceId);
  }

  getChildren(competenceId: string): string[] {
    return this._parentIdToChildIds.get(competenceId) ?? [];
  }

  /**
   * Maps a competence id of any level to the id of the topic that contains it.
   * @param competenceId
   */
  getTopicId(competenceId: string): string {
    const chain = this.getRelationshipChain(competenceId);
    // Topic id is alwauys the last element in the chain.
    return chain[chain.length - 1];
  }
}
