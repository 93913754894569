import { SVGProps } from "react";

const SvgIconActionDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 9h17l-1 18h-15l-1-18ZM19 12.5v11m-6-11v11M12 5h8v4h-8V5ZM5 9h22"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgIconActionDelete;
