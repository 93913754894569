import Cookies from "js-cookie";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { Assert } from "../assert";
import { isRunningInServerEnv } from "@/utils/isRunningInServerEnv";
import { MotivationScanAnswers } from "@/utils/motivationScan";
import { useEffect, useState } from "react";

export interface CookieConsent {
  haveMadeChoice: boolean;
  haveConsentForWebAnalytics: boolean;
}

export enum CookieKeys {
  CONSENT = "ECIU.cc_cookie",
  MOTIVATION_SCAN_ANSWERS = "ECIU.motivation_scan_answers",
}

export function getCookieConsent(): CookieConsent {
  const consentStr = Cookies.get(CookieKeys.CONSENT);
  if (!consentStr) {
    // Default.
    return {
      haveMadeChoice: false,
      haveConsentForWebAnalytics: false,
    };
  }

  const parsed = JSON.parse(consentStr) as CookieConsent;
  return {
    haveMadeChoice: true,
    haveConsentForWebAnalytics: parsed.haveConsentForWebAnalytics,
  };
}

export function saveCookieConsentAndRefreshCookieManagers(
  consent: Omit<CookieConsent, "haveMadeChoice">,
  appInsightsReactPlugin: ReactPlugin
) {
  Assert.check(!isRunningInServerEnv(), "!isRunningInServerEnv()");
  const toSave: CookieConsent = {
    haveMadeChoice: true,
    haveConsentForWebAnalytics: consent.haveConsentForWebAnalytics,
  };
  Cookies.set(CookieKeys.CONSENT, JSON.stringify(toSave), {
    expires: 365, // One year from now.
  });
  appInsightsReactPlugin
    .getCookieMgr()
    .setEnabled(consent.haveConsentForWebAnalytics);
  if (!consent.haveConsentForWebAnalytics) {
    // Remove application insights cookies if they exist.
    Cookies.remove("ai_user");
    Cookies.remove("ai_session");
  }
}

export function getMotivationScanAnswersFromCookie(
  cookies: Record<string, string | undefined>
): MotivationScanAnswers | null {
  const answersStr = cookies[CookieKeys.MOTIVATION_SCAN_ANSWERS];
  return parseMotivationScanAnswers(answersStr);
}

export function useClientSideCookies() {
  const [isCookiesAvailable, setIsCookiesAvailable] = useState(false);
  useEffect(() => {
    Assert.check(!isRunningInServerEnv(), "!isRunningInServerEnv()");
    setIsCookiesAvailable(true);
  }, []);

  function getMotivationScanAnswersFromCookie() {
    Assert.check(
      isCookiesAvailable,
      "isCookiesAvailable must be true before this can be called."
    );
    const answersStr = Cookies.get(CookieKeys.MOTIVATION_SCAN_ANSWERS);
    return parseMotivationScanAnswers(answersStr);
  }
  return {
    isCookiesAvailable,
    getMotivationScanAnswers: getMotivationScanAnswersFromCookie,
  };
}

function parseMotivationScanAnswers(answersStr: string | undefined) {
  if (!answersStr) {
    return null;
  }
  try {
    const answers = JSON.parse(answersStr) as MotivationScanAnswers;
    Assert.notNull(
      answers,
      "Answers should not be null after successful parse."
    );
    Assert.check(typeof answers === "object", "typeof answers === 'object'");
    return answers;
  } catch (err) {
    console.error(err);
    return null;
  }
}
