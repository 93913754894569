import { SVGProps } from "react";

const SvgIconActionWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_356_5803)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8281 19.5L12.218 2.98273L2.60798 19.5L21.8281 19.5ZM13.5145 2.22839C12.9361 1.23417 11.5 1.23417 10.9215 2.22839L1.31146 18.7456C0.729646 19.7456 1.45105 21 2.60798 21L21.8281 21C22.985 21 23.7064 19.7456 23.1246 18.7456L13.5145 2.22839Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75L12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75L11.25 6.75C11.25 6.33579 11.5858 6 12 6Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.5C12.2071 16.5 12.375 16.3321 12.375 16.125C12.375 15.9179 12.2071 15.75 12 15.75C11.7929 15.75 11.625 15.9179 11.625 16.125C11.625 16.3321 11.7929 16.5 12 16.5ZM12 18C13.0355 18 13.875 17.1605 13.875 16.125C13.875 15.0895 13.0355 14.25 12 14.25C10.9645 14.25 10.125 15.0895 10.125 16.125C10.125 17.1605 10.9645 18 12 18Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_356_5803">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24 24) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconActionWarning;
