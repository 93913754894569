/**
 * NextJS server exception. Swagger exception clone.
 */
export class ServerException extends Error {
  override message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isServerException = true;

  static isServerException(obj: any): obj is ServerException {
    return obj.isServerException === true;
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  throw new ServerException(message, status, response, headers, result);
}
