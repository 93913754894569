import { mcn } from "@/utils/mergeClassNames";
import { Either } from "@/utils/typeUtils";
import Link, { LinkProps as NextLinkProps } from "next/link";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  ReactNode,
} from "react";

export type ButtonKind =
  | "primary"
  | "secondary"
  | "tertiary"
  | "link"
  | "i-like-it";

type LocalButtonProps = {
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>["disabled"];
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

type LinkProps = {
  href: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  forwardRef?: ForwardedRef<HTMLAnchorElement>;
  prefetch?: NextLinkProps["prefetch"];
  plainHtmlATag?: boolean;
};

export type ButtonProps = Either<LocalButtonProps, LinkProps> & {
  children?: ReactNode;
  className?: string;
  label?: string;
  kind?: ButtonKind;
  size?: "small" | "default";
};

const Button = (props: ButtonProps) => {
  const {
    children,
    label,
    kind = "primary",
    size = "default",
    className,
    forwardRef,
    ...rest
  } = props;

  const classNames = mcn(
    size === "default" && "h-[48px] px-lg text-style-button",
    size === "small" && "h-[28px] px-md text-style-subtitle2 uppercase",
    "rounded-3 text-center",
    "inline-flex items-center justify-center",

    kind === "primary" && "bg-red-100 text-blue",
    kind === "secondary" && "bg-yellow-100 hover:bg-yellow-110 text-blue",
    kind === "tertiary" && "bg-blue text-red-100",
    kind === "link" && "bg-[transparent] text-blue-120",

    //hover
    kind === "primary" && "hover:bg-red-110",
    kind === "secondary" && "hover:bg-yellow-110",
    kind === "tertiary" && "hover:bg-blue-120",
    kind === "link" && "hover:bg-light-blue/10",

    //active
    kind === "primary" && "active:bg-red-120",
    kind === "secondary" && "active:bg-yellow-120",
    kind === "tertiary" && "active:bg-blue-120/50",
    kind === "link" && "active:bg-light-blue/20",

    // focus
    "focus-visible:default-focus",

    // disabled
    "disabled:bg-grey-40",
    kind === "tertiary" && "disabled:text-blue",
    kind === "link" && "disabled:bg-[transparent] disabled:text-grey-120",

    className
  );

  if (rest.href) {
    const { href, prefetch, plainHtmlATag: nativeLinkTag, ...aProps } = rest;
    if (nativeLinkTag)
      return (
        <a
          data-testid={"btn-link"}
          className={classNames}
          href={href}
          {...aProps}
          ref={forwardRef}
        >
          {label || children}
        </a>
      );
    else
      return (
        <Link
          data-testid={"btn-link"}
          {...aProps}
          href={href}
          prefetch={prefetch}
          className={classNames}
        >
          {label || children}
        </Link>
      );
  } else {
    return (
      <button data-testid={"btn"} className={classNames} {...rest}>
        {label || children}
      </button>
    );
  }
};

export default Button;
