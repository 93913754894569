import { SVGProps } from "react";

const SvgIconLogoSdgWheel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 176 176"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="icon-logo-sdg-wheel_svg__a" d="M0 0h165.488v84.163H0z" />
      <path id="icon-logo-sdg-wheel_svg__c" d="M0 0h96.805v72.037H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M45.214 62.912a49.97 49.97 0 0 1 7.888-10.146l-25.864-28.36a88.454 88.454 0 0 0-16.421 21.372l34.397 17.134"
        fill="#5BB249"
      />
      <path
        d="M108.618 42.876a49.3 49.3 0 0 1 10.847 6.795l25.938-28.317a88.281 88.281 0 0 0-22.987-14.341l-13.798 35.863"
        fill="#D8AE48"
      />
      <path
        d="m168.017 51.404-34.376 17.137a49.317 49.317 0 0 1 3.443 12.147l38.24-3.61a87.254 87.254 0 0 0-7.307-25.674"
        fill="#B32939"
      />
      <g transform="translate(.06 25.113)">
        <mask id="icon-logo-sdg-wheel_svg__b" fill="#fff">
          <use xlinkHref="#icon-logo-sdg-wheel_svg__a" />
        </mask>
        <path
          d="m131.115 38.458 34.373-17.132A88.34 88.34 0 0 0 149.424 0l-25.937 28.312a49.827 49.827 0 0 1 7.628 10.146"
          fill="#4A9C47"
          mask="url(#icon-logo-sdg-wheel_svg__b)"
        />
        <path
          d="M38.321 62.889c0-.773.028-1.547.061-2.316L.132 57.15a91.31 91.31 0 0 0-.193 5.74c0 7.335.91 14.463 2.604 21.274l36.943-10.597a49.657 49.657 0 0 1-1.165-10.677"
          fill="#3F794A"
          mask="url(#icon-logo-sdg-wheel_svg__b)"
        />
      </g>
      <path
        d="M126.36 119.44a50.252 50.252 0 0 1-9.327 8.775l20.195 32.698a88.629 88.629 0 0 0 19.776-18.367l-30.643-23.107"
        fill="#F4BB39"
      />
      <path
        d="M137.618 88.002c0 3.621-.39 7.151-1.137 10.553l36.938 10.614A87.805 87.805 0 0 0 176 88.002c0-1.812-.06-3.612-.172-5.401l-38.246 3.61c.023.6.036 1.192.036 1.791"
        fill="#DE4A3B"
      />
      <path
        d="m50.26 120.17-30.572 23.235a88.715 88.715 0 0 0 19.965 18.088l20.19-32.664a50.032 50.032 0 0 1-9.584-8.66"
        fill="#ED9643"
      />
      <path
        d="M39.003 80.169a49.008 49.008 0 0 1 3.664-12.324L8.303 50.726A87.397 87.397 0 0 0 .718 76.735l38.285 3.434"
        fill="#238CC0"
      />
      <path
        d="m132.53 163.872-20.165-32.657a49.457 49.457 0 0 1-11.77 4.783l7.117 37.774a87.434 87.434 0 0 0 24.818-9.9"
        fill="#8B2242"
      />
      <g transform="translate(75.112 103.903)">
        <mask id="icon-logo-sdg-wheel_svg__d" fill="#fff">
          <use xlinkHref="#icon-logo-sdg-wheel_svg__c" />
        </mask>
        <path
          d="M59.899 0a49.657 49.657 0 0 1-5.37 11.056l30.668 23.131a87.51 87.51 0 0 0 11.608-23.58L59.9 0"
          fill="#2BB8CF"
          mask="url(#icon-logo-sdg-wheel_svg__d)"
        />
        <path
          d="M20.045 33.198a49.93 49.93 0 0 1-7.157.52 49.92 49.92 0 0 1-5.775-.342L0 71.153a88.403 88.403 0 0 0 27.154-.213l-7.11-37.742"
          fill="#E46A3E"
          mask="url(#icon-logo-sdg-wheel_svg__d)"
        />
      </g>
      <path
        d="M91.173 38.482a49.16 49.16 0 0 1 12.279 2.371l13.794-35.855A87.424 87.424 0 0 0 91.173.065v38.417"
        fill="#DB3244"
      />
      <path
        d="M76.756 136.334a49.314 49.314 0 0 1-12.18-4.605l-20.204 32.678a87.653 87.653 0 0 0 25.278 9.663l7.106-37.736"
        fill="#CE2865"
      />
      <path
        d="M73.089 40.677a49.123 49.123 0 0 1 12.533-2.232V.033a87.664 87.664 0 0 0-26.423 4.813l13.89 35.831"
        fill="#1E445B"
      />
      <path
        d="M46.894 115.756a49.356 49.356 0 0 1-5.853-11.732L4.126 114.616a87.608 87.608 0 0 0 12.193 24.375l30.575-23.235"
        fill="#B88F3F"
      />
      <path
        d="M57.259 49.083a49.624 49.624 0 0 1 10.64-6.438L54.018 6.834A87.9 87.9 0 0 0 31.376 20.7l25.883 28.383"
        fill="#1D648D"
      />
    </g>
  </svg>
);

export default SvgIconLogoSdgWheel;
