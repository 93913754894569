import Button, { ButtonProps } from "@/components/Button";
import { mcn } from "@/utils/mergeClassNames";
import { Menu } from "@headlessui/react";
import Link from "next/link";
import { forwardRef, PropsWithChildren } from "react";

const HeadlessUiMenuLinkWrapper = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  const { href, ...rest } = props;
  return <li className="list-none"><Link href={href ?? ""} ref={ref} {...rest}></Link></li>;
});
HeadlessUiMenuLinkWrapper.displayName = "HeadlessUiMenuLinkWrapper";

type LinkItemProps = PropsWithChildren<{
  href: string;
  className?: string;
}>;

export const HeadlessUiMenuLink = (props: LinkItemProps) => {
  const { className, ...rest } = props;
  return (
    <Menu.Item>
      {({ active }) => (
        <HeadlessUiMenuLinkWrapper
          className={mcn(active && "link", className)}
          {...rest}
        />
      )}
    </Menu.Item>
  );
};

type LinkButtonItemProps = LinkItemProps & {
  kind?: ButtonProps["kind"];
};
const ButtonLinkWrapper = forwardRef<HTMLAnchorElement, LinkButtonItemProps>(
  (props, ref) => {
    const { href, ...rest } = props;
    return <Button href={href ?? ""} forwardRef={ref} {...rest} />;
  }
);
ButtonLinkWrapper.displayName = "ButtonLinkWrapper";

export const HeadlessUiMenuLinkButton = (props: LinkButtonItemProps) => {
  const { className, ...rest } = props;
  return (
    <Menu.Item>
      {({ active }) => (
        <ButtonLinkWrapper
          className={mcn(active && "link", className)}
          {...rest}
        />
      )}
    </Menu.Item>
  );
};
