import { useLogging } from "@/utils/appInsights/client";
import { QueryClient } from "react-query";
import { ServerException } from "utils/nextjsFetchException";

export type ErrorMessageBuilder<T> = (error: unknown, variables?: T) => string;

export type ErrorOptions<T> = {
  message?: string;
  mutationMessageBuilder?: ErrorMessageBuilder<T>;
};

function redirectHttpStatusCodes(error: ServerException) {
  if (error.status === 401) {
    window.location.assign(`/${error.status}`);
  }
}

export function useDefaultOnError<T>(options?: ErrorOptions<T>) {
  const { logException } = useLogging();

  return {
    query: (error: unknown) => {
      if (ServerException.isServerException(error) && error.status === 401) {
        redirectHttpStatusCodes(error);
      }

      logException(error, {
        message: "Frontend error",
      });
    },
    mutation: (error: unknown, variables?: T) => {
      if (ServerException.isServerException(error)) {
        redirectHttpStatusCodes(error);
      }

      logException(error, {
        message: !!options?.mutationMessageBuilder
          ? options.mutationMessageBuilder(error, variables)
          : options?.message ?? "Frontend error",
      });
    },
  };
}

const useApplicationReactQueryClient = () => {
  const onError = useDefaultOnError();

  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 15, // 15 minutes
        retry: false,
        onError: onError.query,
      },
      mutations: {
        onError: onError.mutation,
      },
    },
  });
};

export default useApplicationReactQueryClient;
