import { Assert } from "@/utils/assert";

const config = {
  staticRevalidationTime: readStaticRevalidationTime(),
  baseUrl: readBaseUrl(),
  recaptcha: {
    siteKey: process.env.NEXT_PUBLIC_DXP_RECAPTCHA_SITE_KEY ?? null,
    secretKey: process.env.DXP_RECAPTCHA_SECRET_KEY ?? null,
  },
  auth: {
    signInUri: "/.auth/login/aad",
    signInDomainHint: process.env.NEXT_PUBLIC_DXP_SIGNIN_DOMAIN_HINT,
    signOutUri: "/.auth/logout?post_logout_redirect_uri=/",
    signUpUri: process.env.NEXT_PUBLIC_DXP_SIGNUP_URL ?? "",
    accountUri: process.env.NEXT_PUBLIC_DXP_ACCOUNT_URL ?? "",
  },
  api: {
    baseUrl: process.env.DXP_BACKEND_BASE_URL,
  },
  msHeaders: {
    AadAccessToken: "x-ms-token-aad-access-token",
    PrincipalName: "x-ms-client-principal-name", // username with domain (<user>@test.eciu.eu)
    PrincipalId: "x-ms-client-principal-id", // AzureAD id (GUID - same as azureAdUserId from backend)
  },
  cdn: {
    url: process.env.NEXT_PUBLIC_DXP_CDN_BASE_URL,
  },
  cookies: {
    UserDataCookieKey: "DXP-USER-DATA",
  },
};

function readStaticRevalidationTime(): number {
  const defaultTime = 5 * 60; // 5 minutes.
  const parsedTime = parseInt(process.env.DXP_STATIC_REVALIDATION_TIME ?? "");
  if (!isNaN(parsedTime)) {
    return parsedTime;
  }
  return defaultTime;
}

function readBaseUrl(): string {
  const baseUrl = process.env.NEXT_PUBLIC_DXP_URL;
  Assert.check(baseUrl !== undefined, "NEXT_PUBLIC_DXP_URL !== undefined");
  return baseUrl;
}

export default config;
