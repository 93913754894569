import { mcn } from "@/utils/mergeClassNames";
import { PropsWithChildren } from "react";
import { BrowseMenuDesktop, BrowseMenuMobile } from "./BrowseMenu";
import { HeaderLogoLink } from "./HeaderLogoLink";

export const headerHeightClassnames = "h-site-header-mobile laptop:h-site-header-desktop";

type Props = PropsWithChildren<{
  transparentHeader?: boolean;
  logoHref?: string;
  sidebarDesktop?: JSX.Element;
  browseMenuContent?: {
    mobile: JSX.Element;
    desktop: JSX.Element;
  };
}>;
export const Header = (props: Props) => {
  const hasSidebar = !!props.sidebarDesktop;

  return (
    <header
      className={mcn(
        "bg-blue border-b border-light-blue",
        "laptop:border-none",
        props.transparentHeader && "laptop:bg-[transparent]",
        "sticky top-0 laptop:absolute w-full z-50",
        "flex items-center",
        headerHeightClassnames
      )}
    >
      {hasSidebar && (
        <div
          className={mcn(
            "hidden laptop:flex",
            "flex-col shadow-inner",
            "fixed z-50 top-0 h-screen w-[160px]",
            "bg-light-blue text-white"
          )}
        >
          <HeaderLogoLink href={props.logoHref} />
          <div className="hidden laptop:flex flex-col h-full">
            {props.sidebarDesktop}
          </div>
        </div>
      )}

      <div
        className={mcn(
          "flex-grow",
          "laptop:absolute laptop:w-[160px]",
          hasSidebar && "laptop:hidden"
        )}
      >
        <HeaderLogoLink href={props.logoHref} />
      </div>

      <div className="hidden laptop:flex items-center container h-full">
        {props.browseMenuContent?.desktop && (
          <BrowseMenuDesktop>
            {props.browseMenuContent.desktop}
          </BrowseMenuDesktop>
        )}
        <div
          className={mcn(
            "hidden laptop:flex items-center gap-sm",
            "absolute top-0 right-xl",
            headerHeightClassnames
          )}
        >
          {props.children}
        </div>
      </div>

      <div className="laptop:hidden flex items-center px-2xs gap-2xs">
        {props.children}
        {props.browseMenuContent?.mobile && (
          <BrowseMenuMobile>{props.browseMenuContent.mobile}</BrowseMenuMobile>
        )}
      </div>
    </header>
  );
};
