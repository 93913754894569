export const formatDate = (
  type: "short" | "long",
  d: Date | string | undefined | null,
  includeYear?: boolean
) => {
  if (typeof d === "string") d = new Date(d);
  return d?.toLocaleString("en-GB", {
    year: includeYear ? "numeric" : undefined,
    month: type,
    day: "numeric",
  });
};

export const formatPeriod = (
  type: "short" | "long",
  start: Date | null,
  end: Date | null
) => {
  if (!start && !end) return "";
  const includeFromYear =
    type === "long" && start?.getFullYear() !== end?.getFullYear();
  const includeToYear = type === "long";
  const from = formatDate(type, start, includeFromYear) ?? "";
  const to = formatDate(type, end, includeToYear) ?? "";
  return `${from} – ${to}`;
};

export function formatPeriodIso(start: Date | null, end: Date | null) {
  if (!start && !end) {
    return "";
  }
  const from = start?.toISOString().split("T")[0] ?? "";
  const to = end?.toISOString().split("T")[0] ?? "";
  return `${from} – ${to}`;
}

export const getDateMonthShort = (date: Date | undefined) => {
  if (!date) return "";
  return date?.toLocaleString("en-GB", { month: "short" });
};

export const isDateInPeriod = (
  date: Date,
  start: Date | null,
  end: Date | null
) => {
  if (!start || !end) return false;
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
};

export function getDateOrNull(date: string | null): Date | null {
  if (date) {
    return new Date(date);
  } else {
    return null;
  }
}
