import React, { useEffect, useReducer, useState } from "react";
import { useRouter } from "next/router";

export interface ProgressState {
  progress: number;
  showBar: boolean;
  trickleDispatchIntervalId: number | null;
}

type RouteChangeProgressAction =
  | {
      type: "routeChangeStarted";
      trickleDispatchIntervalId: number;
    }
  | {
      type: "trickle";
    }
  | {
      type: "routeChangeCompleted";
    };

function useRouteChangeProgressBar() {
  function progressReducer(
    state: ProgressState,
    action: RouteChangeProgressAction
  ): ProgressState {
    const newProgress = Math.min(state.progress + 8, 80);
    switch (action.type) {
      case "routeChangeStarted":
        return {
          showBar: true,
          progress: 8,
          trickleDispatchIntervalId: action.trickleDispatchIntervalId,
        };
      case "trickle":
        // Don't trickle beyond 100 whatever happens.
        if (state.progress === 100) {
          return state;
        }
        return {
          ...state,
          progress: newProgress,
        };
      case "routeChangeCompleted":
        if (state.trickleDispatchIntervalId) {
          window.clearInterval(state.trickleDispatchIntervalId);
        }
        return {
          showBar: false,
          progress: 100,
          trickleDispatchIntervalId: null,
        };
      default:
        throw new Error();
    }
  }

  const [progressBarState, progressBarDispatch] = useReducer(progressReducer, {
    progress: 0,
    showBar: false,
    trickleDispatchIntervalId: null,
  });

  return {
    progressBarState,
    progressBarDispatch,
  };
}

export function RouteChangeProgressBar() {
  const { progressBarState, progressBarDispatch } = useRouteChangeProgressBar();
  const [showProgressBar, setShowProgressBar] = useState(false);

  // Listen for route changes through router events.
  const router = useRouter();
  useEffect(() => {
    const handleStart = (url: string, param: { shallow: boolean }) => {
      // We skip shallow route changes.
      // Example: Selecting a new filter in /browse page.
      const shallow = param.shallow;
      if (shallow) {
        return;
      }

      const intervalId = window.setInterval(() => {
        progressBarDispatch({ type: "trickle" });
      }, 200);
      progressBarDispatch({
        type: "routeChangeStarted",
        trickleDispatchIntervalId: intervalId,
      });
    };

    const handleComplete = () => {
      progressBarDispatch({ type: "routeChangeCompleted" });
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
    };
  }, [progressBarDispatch, router.events]);

  useEffect(() => {
    if (progressBarState.showBar) {
      setShowProgressBar(true);
    }

    // Delay the transition for 200ms.
    if (!progressBarState.showBar) {
      setTimeout(() => {
        setShowProgressBar(false);
      }, 400);
    }
  }, [progressBarState.showBar]);

  return (
    <>
      {showProgressBar && (
        <>
          <div
            style={{ width: `${progressBarState.progress}%` }}
            className="fixed w-full h-xs bg-yellow-100 z-[60]"
          />
        </>
      )}
    </>
  );
}
