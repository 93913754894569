import { SVGProps } from "react";

const SvgIconActionSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="icon-action-sign-out_svg__a">
        <path d="M1920 0v4137H0V0h1920z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#icon-action-sign-out_svg__a)"
      transform="translate(-24 -1028)"
    >
      <path
        d="M39.75 1046.309v2.294c0 .633-.588 1.147-1.313 1.147h-7.874c-.725 0-1.313-.514-1.313-1.147v-17.206c0-.633.588-1.147 1.313-1.147h7.875c.724 0 1.312.514 1.312 1.147v2.294m.75 3.684 3 2.625-3 2.625m2.438-2.625H34.5"
        stroke="currentColor"
        strokeWidth={1.125}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgIconActionSignOut;
