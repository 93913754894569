import { IconHamburgerOpenClosed } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import { Menu, Transition } from "@headlessui/react";
import { PropsWithChildren, useEffect } from "react";

export const BrowseMenuMobile = (props: PropsWithChildren<unknown>) => {
  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            aria-label="Browse learning opportunities and topics"
            className={mcn(
              "flex rounded-3 p-xs",
              "focus-visible:default-focus focus-visible:touch-screen-no-focus",
              "bg-blue text-white",
              "hover:bg-red-100/10" // same as "link button" which is used right beside this button
            )}
            data-testid={"browse-menu-mobile-button"}
          >
            <IconHamburgerOpenClosed open={open} aria-hidden fontSize={32} />
          </Menu.Button>

          <ItemsWrapper open={open}>{props.children}</ItemsWrapper>
        </>
      )}
    </Menu>
  );
};

const ItemsWrapper = (props: PropsWithChildren<{ open: boolean }>) => {
  useEffect(() => {
    if (props.open) {
      window.requestAnimationFrame(() => {
        document.body.style.overflow = "hidden";
      });
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [props.open]);

  return (
    <Transition
      className={mcn(
        "fixed top-site-header-mobile right-0 left-0",
        "bg-light-blue",
        "overflow-hidden"
      )}
      enter="transition-[height] duration-[250ms] ease-out"
      enterFrom="h-0"
      enterTo="h-[calc(100dvh-56px)]"
      leave="transition-[height] duration-200 ease-out"
      leaveFrom="h-[calc(100dvh-56px)]"
      leaveTo="h-0"
    >
      <Menu.Items className="py-lg h-full overflow-y-scroll styled-scrollbar outline-none">
        {props.children}
      </Menu.Items>
    </Transition>
  );
};
