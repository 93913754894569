import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  open?: boolean;
};

// Merge of IconActionHamburger and IconActionClose with animation between open/closed
export const IconHamburgerOpenClosed = (props: Props) => {
  const closePath = "M8 8 24 24M24 8 8 24";
  const hamburderPath = "M24 11.5 8 11.5M8 19.5 24 19.5";
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="transition-[d] duration-200"
        d={props.open ? closePath : hamburderPath}
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
