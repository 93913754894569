import { Header } from "@/components/Header";
import {
  initClientSideAppInsights,
  trackWebVitals,
} from "@/utils/appInsights/client";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useApplicationReactQueryClient from "lib/reactQueryClient";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import Head from "next/head";
import { QueryClientProvider } from "react-query";
import { NextPageWithLayout } from "types/nextjs";
import "../styles/globals.css";
import { RouteChangeProgressBar } from "@/components/RouteChangeProgressBar";
import config from "frontend-main/config";
import { RecaptchaScript } from "@/components/RecaptchaScript";
import React from "react";
import { EABaseContextProvider } from "../components/EABaseContext";
import {
  getMemberUniversities,
  OrganisationBase,
} from "@/server/backend-api/organisation";
import { ContactFormSetting } from "@/server/backend-api/generated";
import { getContactFormSettings } from "@/server/backend-api/support";
import { isBackendClientAllowed } from "@/server/backend-api/createBackendApiClient";
import type {
  AppContext,
  AppInitialProps,
} from "next/app";
import App from "next/app";
import {
  getLearningOpportunities,
  LearningOpportunityName,
} from "@/server/backend-api";

const { reactPlugin, appInsights } = initClientSideAppInsights(
  process.env
    .NEXT_PUBLIC_DXP_EXTERNAL_AUTH_APP_INSIGHTS_CLIENT_CONNECTION_STRING
);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  trackWebVitals(appInsights, metric);
}

function MyApp(
props: AppProps & {
  Component: NextPageWithLayout;
}& ExtraInitialProps
) {
  const { Component, pageProps } = props;
  const client = useApplicationReactQueryClient();
  const recaptchaSrc = `https://www.google.com/recaptcha/api.js?render=${
    config.recaptcha.siteKey ?? ""
  }`;
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Head>
        <title>ECIU Account</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Sign up for ECIU" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <EABaseContextProvider
       memberUniversities={props.memberUniversities}
       contactFormSettings={props.contactFormSettings}
       learningOpps={props.learningOppNames}
       >
      <QueryClientProvider client={client}>
        <RecaptchaScript />
        <RouteChangeProgressBar />
        <Header
          transparentHeader={Component.layout?.transparentHeader ?? true}
          logoHref={process.env.NEXT_PUBLIC_DXP_URL}
        />
        <Component {...pageProps} />
      </QueryClientProvider>
      </EABaseContextProvider>
    </AppInsightsContext.Provider>
  );
}

interface ExtraInitialProps {
  memberUniversities: OrganisationBase[];
  contactFormSettings: ContactFormSetting[];
  learningOppNames: LearningOpportunityName[];
}

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<ExtraInitialProps> => {
  const appProps = await App.getInitialProps(appContext);

  const memberUniversitiesPromise = !isBackendClientAllowed
    ? Promise.resolve([])
    : getMemberUniversities();
  const contactFormSettingsPromise = isBackendClientAllowed
    ? getContactFormSettings()
    : Promise.resolve([]);
    const learningOppNamesPromise = isBackendClientAllowed
    ? getLearningOpportunities()
    : Promise.resolve([]);

  const learningOppNames: LearningOpportunityName[] = (
    await learningOppNamesPromise
  )
    .map((x) => ({
      id: x.id,
      studyPeriod: x.studyPeriod ?? "",
      studyPeriodShort: x.studyPeriodShort ?? "",
      name: x.title ?? "",
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    memberUniversities: await memberUniversitiesPromise,
    contactFormSettings: await contactFormSettingsPromise,
    learningOppNames: learningOppNames,
  };
};

export default MyApp;
