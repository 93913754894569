import {
  CompetenceBaseTreeEx,
} from "@/server/backend-api";
import React, { FC, PropsWithChildren, useContext } from "react";
import { OrganisationBase } from "@/server/backend-api/organisation";
import { ContactFormSetting } from "@/server/backend-api/generated";
import {
  LearningOpportunityName,
} from "@/server/backend-api";

type EABaseContext = {
  memberUniversities: OrganisationBase[];
  contactFormSettings: ContactFormSetting[];
  learningOppNames: LearningOpportunityName[];
};

const EABaseContext = React.createContext<EABaseContext>({
  memberUniversities: [],
  contactFormSettings: [],
  learningOppNames: [],
});

interface Props {
  memberUniversities: OrganisationBase[];
  contactFormSettings: ContactFormSetting[];
  learningOpps: LearningOpportunityName[];
}

export const EABaseContextProvider: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <EABaseContext.Provider
      value={{
        memberUniversities: props.memberUniversities,
        contactFormSettings: props.contactFormSettings,
        learningOppNames: props.learningOpps,
      }}
    >
      {props.children}
    </EABaseContext.Provider>
  );
};

export const useEABaseContext = () => {
  return useContext(EABaseContext);
};
