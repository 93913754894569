import { ServerRequest } from "../../types";
import { AuthStatus, getAuthStatus } from "../getAuthStatus";
import { createBackendApiClient } from "./createBackendApiClient";
import {
  LearningOpportunityUserData,
  LearningOpportunityUserDataWithMicroCredentials,
  LearningOpportunityUserStatus,
} from "@/server/backend-api/generated";
import { getDateOrNull } from "@/utils/dateUtils";
import { Assert } from "@/utils/assert";

export type UserAdmissionData = {
  admissionId: string;
  status: LearningOpportunityUserStatus | null;
  createdDate?: string | null;
  offeredDate?: string | null;
  offerResponseDate?: string | null;
  offerDueDate?: string | null;
  deniedDate?: string | null;
};

export interface UserAdmissionDataWithMicroCredentials
  extends UserAdmissionData {
  isMicroCredentialAvailable: boolean;
  isCompletedButUnauthorizedToSeeMicroCredential: boolean;
}

export type UserAdmissionFile = {
  name: string;
  documentTypeName: string;
};

export const mapUserAdmissionData = (
  userData: LearningOpportunityUserData | null
): UserAdmissionData | null => {
  const admission = userData?.admission;
  if (!admission) return null;
  Assert.notNull(
    admission.id,
    "Expected admission ID to not be null or undefined."
  );
  return {
    admissionId: admission.id,
    status: userData?.status,
    createdDate: getDateOrNull(admission?.createdDate)?.toJSON() ?? null,
    offeredDate: getDateOrNull(admission?.offeredDate)?.toJSON() ?? null,
    offerResponseDate:
      getDateOrNull(admission?.offerResponseDate)?.toJSON() ?? null,
    offerDueDate: getDateOrNull(admission?.offerDueDate)?.toJSON() ?? null,
    deniedDate: getDateOrNull(admission?.deniedDate)?.toJSON() ?? null,
  };
};

export function mapUserAdmissionWithMicroCredentials(
  userData: LearningOpportunityUserDataWithMicroCredentials | null
): UserAdmissionDataWithMicroCredentials | null {
  if (userData === null) {
    return null;
  }

  const admissionData = mapUserAdmissionData(userData);
  Assert.notNull(admissionData);

  return {
    ...admissionData,
    isMicroCredentialAvailable: userData.microCredentialAvailable,
    isCompletedButUnauthorizedToSeeMicroCredential:
      userData.status === LearningOpportunityUserStatus.LearnerCompleted &&
      userData.learnerCompletedButNoLinkedIdToLOHostingUniversityAndTooLowAssuranceLevel,
  };
}

export const acceptAdmission = async (
  admissionId: string,
  authStatus: AuthStatus
) => {
  const client = createBackendApiClient(authStatus.token);
  await client.admission_AcceptAdmission(admissionId);
};

export const rejectAdmission = async (
  admissionId: string,
  authStatus: AuthStatus
) => {
  const client = createBackendApiClient(authStatus.token);
  await client.admission_RejectAdmission(admissionId);
};

export const getAdmissionFiles = async (
  admissionId: string,
  userRequest?: ServerRequest
): Promise<UserAdmissionFile[]> => {
  const auth = userRequest ? getAuthStatus(userRequest) : undefined;
  const client = createBackendApiClient(auth?.token);
  const files = await client.admission_GetFiles(admissionId);

  return files.map((file) => ({
    name: file.name,
    documentTypeName: file.documentTypeName,
  }));
};
