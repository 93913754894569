import config from "../config";
import { ServerRequest } from "../types";
import { Assert } from "@/utils/assert";
import jwtDecode, { JwtPayload } from "jwt-decode";
import dayjs from "dayjs";
import { IncomingMessage } from "http";

export type AuthStatus = StatusAuthed | StatusUnAuthed;
type StatusAuthed = {
  isAuthenticated: true;
  token: string;
  userName?: string;
  userId?: string;
};

type StatusUnAuthed = {
  isAuthenticated: false;
  token: undefined;
  userName: undefined;
  userId: undefined;
};

export const getAuthStatus = (req: IncomingMessage): AuthStatus => {
  const token = req.headers[config.msHeaders.AadAccessToken];
  const userName = req.headers[config.msHeaders.PrincipalName];
  const userId = req.headers[config.msHeaders.PrincipalId];
  // If array of strings, fail fast.
  Assert.check(typeof token === "string" || token === undefined);
  Assert.check(typeof userName === "string" || userName === undefined);
  Assert.check(typeof userId === "string" || userId === undefined);
  return getAuthStatusFromToken(token, userName, userId);
};

export function getAuthStatusFromToken(
  token?: string,
  userName?: string,
  userId?: string
) {
  const unAuthed = {
    isAuthenticated: false,
    token: undefined,
    userName: undefined,
    userId: undefined,
  } as const;

  if (token === undefined) {
    return unAuthed;
  }

  const parsedToken = jwtDecode<JwtPayload>(token);
  if (tokenHasExpired(parsedToken)) {
    return unAuthed;
  }

  return {
    isAuthenticated: true,
    token: token,
    userName: userName,
    userId: userId,
  } as const;
}

function tokenHasExpired(token: JwtPayload): boolean {
  const expirationTimeInUnixTime = token.exp;
  Assert.check(expirationTimeInUnixTime !== undefined);

  const expirationTime = dayjs.unix(expirationTimeInUnixTime);
  const now = dayjs();
  return now.isAfter(expirationTime);
}
